@media (orientation: portrait) and (max-width: 430px) {
  /* ---------- Universal ---------- */
  p {
    font-size: 4.3vw;
  }
  footer {
    font-size: 4vw;
  }
  /* ---------- Home ---------- */
  .hero-text {
    font-size: 16vw;
    left: -2%;
  }
  .hero-text span:hover {
    text-indent: 0;
    letter-spacing: 0;
  }
  .menu-1 {
    /* top -2 ~ 92 */
    top: 22%;
    width: 90%;
  }
  .menu-2 {
    top: 43.5%;
    width: 80%;
  }
  .menu-3 {
    top: 65%;
    width: 75%;
  }
  .lang {
    font-size: 5vw;
    left: 81%;
    width: 15vw;
    text-align: left;
  }
  .lang span:hover {
    text-indent: .15em;
  }
  .menu-en {
    top: 10%;
  }
  .menu-de {
    top: 15.5%;
  }
  .menu-fr {
    top: 20.5%;
  }
  .menu-sc {
    top: 25.5%;
  }
  .menu-tc {
    top: 30.5%;
  }
  .tap-indication {
    font-size: 5.8vmin;
    position: fixed;
    text-align: center;
    width: 100%;
    bottom: 4%;
    animation: bounce .5s cubic-bezier(.5, .6, .6, 1) infinite alternate;
    cursor: pointer;
    display: block;
  }
  @keyframes bounce {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(-8px);
    }
  }
  /* ---------- About ---------- */
  .arrow-back {
    margin: 10vh 0 0 86vw;
    font-size: 5vw;
    width: 11%;
  }
  .arrow-back span:hover {
    text-transform: lowercase;
  }
  .titles {
    left: 4.6%;
  }
  .titles > span {
    font-size: 11.6vw;
  }
  .menu-4 {
    top: 25%;
    width: 95%;
  }
  .menu-5 {
    top: 52%;
    margin-top: 6vh;
    width: 95%;
  }
  .menu-5 > span {
    font-size: 9vw;
  }
  .about-me-img {
    margin: 88vh -40vw 0 -20vw;
    width: 100%;
  }
  .about-me {
    padding: 100vh 8.5vw 17vh 8.5vw;
  }
  .links {
    margin: 1vh 0 0 -4vw;
    font-size: 4vw;
    width: 35%;
  }
  .links span:hover {
    text-indent: 0;
    letter-spacing: 0;
    transition: none;
  }
  /* ---------- Contact ---------- */
  #contact {
    padding: 0vh 0 3vh 0;
    text-align: center;
  }
  #icons {
    font-size: 11vw;
  }
  #icon {
    font-size: 6vw;
    margin: 1vh 0 -1vh 0;
  }
  footer {
    width: 90%;
    padding-left: 8.5vw;
  }
  /* ---------- BackToTop ---------- */
  #backtoTop-container {
    margin-bottom: 6vh;
  }
  #backtoTop {
    font-size: 7vw;
  }
  /* ---------- On Progress ---------- */
  .on-progress-bg h1 {
    font-size: 12.5vw;
    padding: 40vh 0 0 0;
  }
}